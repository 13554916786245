<template>
  <div class="page-yewuyuan" style="background: #fff; color: #000">
    <van-nav-bar :title="title" left-arrow @click-left="back" :fixed="true" :z-index="999"></van-nav-bar>
    <div class="container font_14 bTop">
      <van-row class="header">
        <van-col span="12" class="bold">单号</van-col>
        <van-col span="12" class="tr">&nbsp;{{ywyDetailObj.billNumber}}</van-col>
        <van-col span="12" class="bold">车型</van-col>
        <van-col span="12" class="tr">{{ywyDetailObj.carModel}}&nbsp;</van-col>
        <van-col span="4" class="bold">进场日期</van-col>
        <van-col span="8">&nbsp;{{ywyDetailObj.joinTime?ywyDetailObj.joinTime.slice(0,10):""}}</van-col>
        <van-col span="4" class="bold">出厂日期</van-col>
        <van-col span="8" class="tr">&nbsp;{{ywyDetailObj.factoryOut?ywyDetailObj.factoryOut.slice(0,10):""}}</van-col>
        <van-col span="4" class="bold">业务来源</van-col>
        <van-col span="8" class="slh">&nbsp;{{ywyDetailObj.source}}</van-col>
        <van-col span="4" class="bold">业务分类</van-col>
        <van-col span="8" class="tr">{{ywyDetailObj.bpType}}</van-col>
      </van-row>
      <div class="tablebox">
        <div class="title flex-wrap"><span>收入明细</span><span class="cRed font_15">￥{{ ywyDetailObj.incomeTotal }}</span></div>
        <van-row class="tc">
          <van-col span="11">项目名称</van-col>
          <van-col span="4">班组</van-col>
          <van-col span="3">单价</van-col>
          <van-col span="3">数量</van-col>
          <van-col span="3">金额</van-col>
        </van-row>
        <div class="mian" v-for="(item, idx) in ywyDetailObj.item" :key="idx">
          <van-row class="mian_row">
            <van-col span="11" class="slh font_13">{{item.itemName}}</van-col>
            <van-col span="4" class="font_13">{{item.groupName}}</van-col>
            <van-col span="3">{{item.price}}</van-col>
            <van-col span="3">{{item.counts}}</van-col>
            <van-col span="3" class="tr">￥{{item.amount}}</van-col></van-row>
        </div>
      </div>
      <div class="tablebox">
        <div class="title flex-wrap"><span>支出明细</span><span class="cRed font_15">￥{{ywyDetailObj.outTotal}}</span></div>
        <van-row class="tc">
          <van-col span="11">项目名称</van-col>
          <van-col span="4">成本类型</van-col>
          <van-col span="3">单价</van-col>
          <van-col span="3">数量</van-col>
          <van-col span="3">金额</van-col>
        </van-row>
        <div class="mian" v-for="(item, idx) in ywyDetailObj.part" :key="idx">
          <van-row class="mian_row">
            <van-col span="11" class="slh font_13">{{item.itemName}}</van-col>
            <van-col span="4">{{item.costType}}</van-col>
            <van-col span="3">{{item.price}}</van-col>
            <van-col span="3">{{item.counts}}</van-col>
            <van-col span="3" class="tr">￥{{item.amount}}</van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div class="footer bold font_15">
      <van-row>
        <van-col span="2"></van-col>
        <van-col span="4">毛利率</van-col>
        <van-col span="8" class="cRed font_15">{{ywyDetailObj.grossMargin}}</van-col>
        <van-col span="4">毛利润</van-col>
        <van-col span="4" class="cRed font_20">￥{{ywyDetailObj.grossProfit}}</van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { ywyDetails } from "@/api/check";
import moment from "moment";
export default {
  data() {
    return {
      isiOS: false,
      isok: false,
      token: "",
      title: "业务详情",
      currentDate: new Date(),
      key: "",
      gid: this.$route.query.gid,
      oid: this.$route.query.oid,
      carNo: this.$route.query.carNo,
      stime: moment().format("YYYY-MM"),
      etime: moment().format("YYYY-MM"),
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2030, 10, 1),
      timeshow: false,
      timename: "选择起始年月",
      loading: false,
      total: 0,
      finished: false,
      ywyDetailObj: {
        accidentCar: 0,
        grossProfit: 0,
        selfPaidCar: 0,
        orderList: [
          {
            accidentCar: 0,
            grossProfit: 0,
            carNo: "",
            selfPaidCar: 0,
            unpaidPayments: 0,
            thisMonthReceivable: 0,
            commission:0,
          },
        ],
        commission:0,
        unpaidPayments: 0,
        thisMonthReceivable: 0,
        total: 0,
      },
    };
  },
  created() {
    this.title = this.carNo + " 业务详情"
    // this.stime = this.$route.query.stime ? this.$route.query.stime : moment().format("YYYY-MM")
    // this.etime = this.$route.query.etime ? this.$route.query.etime : moment().format("YYYY-MM")
    //判断是否在微信环境
    this.appSource();
  },
  mounted() {
    // this.getlist();
  },
  methods: {
    toywy(item) {
      if(item.carNo == "合计") return
      let params = {
        name: item.sellsMan,
        etime: this.etime,
        stime: this.stime,
        gid: this.gid,
      };
      this.$router.push({
        path: "/paint_yewuyuan",
        query: params,
      });
    },
    getlist() {
      this.acv = 5;
      this.ywyDetailObj.orderList = [];
      let params = {
        oid: this.oid,
      };
      ywyDetails(params).then((res) => {
        if (res.code == 200) {
          this.ywyDetailObj = res.data;
        }
      });
    },
    setstiem() {
      this.timename = "选择起始年月";
      this.timeshow = true;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    settiem(e) {
      if (this.timename == "选择起始年月") {
        this.stime = moment(e).format("YYYY-MM");
        this.timeshow = false;

        setTimeout(() => {
          this.timename = "选择截止年月";
          this.timeshow = true;
        }, 500);
      } else {
        this.etime = moment(e).format("YYYY-MM");
        this.getlist();
        this.timeshow = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try {
        if (this.isiOS) {
          this.isok = false;
          this.setupWebViewJavascriptBridge((bridge) => {
            var token = bridge.callHandler(
              "getToken",
              null,
              function responseCallback(responseData) {
                localStorage.setItem("Authorization", responseData);
                _this.token = responseData;
                _this.getlist();
                return responseData;
              }
            );
          });
          console.log("ios");
        } else {
          this.isok = true;
          _this.token = window.androidjs.getLocalData("getToken");
          localStorage.setItem("Authorization", _this.token);
          _this.getlist();
          console.log("andriod");
        }
      } catch (error) {
        _this.token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDk3IiwiZXhwIjoxNzM5NTExNjM3fQ.H9VED5MLq_lGxSVbtuGgQvAGTpz4MePrevma__-ZJos9QCrWDYIULnogSFWg6WAUbh_nM7rq8PrCsl3CvDzOwQ";
        localStorage.setItem("Authorization", _this.token);
        _this.getlist();
      }
    },
    timeshang() {
      this.stime = moment(this.stime)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM");
      this.etime = this.stime;
      this.getlist();
    },
    timexia() {
      this.etime = moment(this.etime)
        .startOf("month")
        .subtract("months", -1)
        .format("YYYY-MM");
      this.stime = this.etime;
      this.getlist();
    },
  },
};
</script>

<style lang="less" scoped>
.container{
  margin-top: 50px;
  padding-bottom: 100px;
  .header {
    .van-col{
      border-bottom: 1px solid #f5f5f5;
      padding: 5px 0;
    }
  }
  .tablebox{
    border-top: 4px solid #f5f5f5;
  }
  .title{
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
  }
}
.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  background-color: #fcecaf;
}
body {
  background: #f6f6f6;
}

.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.fsdd {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;

  .van-search {
    flex: 1;
  }
}

.lanse {
  color: #d80d0d;
  font-weight: bold;
  font-size: 16px;
  padding: 0 2px;
}

.cRed {
  color: #d80d0d;
}

.zuoc {
  width: 15px;
  display: inline-block;
  text-align: left;
}

.boots {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.flsx {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 40px;
  margin-top: 46px;
  background-color: #fff;
}

.flsxsss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 40px;
  padding: 0 12px;
  margin-top: 46px;
}

.topss {
  line-height: 35px;
  font-size: 17px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  color: #666;
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  // line-height: 35px;

  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 5px 10px;
  background: #fff;

  // padding-top: 46px;
  .sgdiv {}

  .ts {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #666;
    transform: scale(0.9);
  }
}

.flx {
  display: flex;
  align-items: center;
}

.flxtop {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid #f5f5f5;
  background-color: #fff;
  padding-top: 10px;
  .f1 {
    flex: 1;

    .bjs {
      background-color: #f5f5f5;
      display: inline-block;
      padding: 0 2px;
      border-radius: 2px;
      line-height: 18px;
    }

    .jinr {
      font-size: 14px;
    }
  }
}

.pt {
  /* padding-top: 46px; */
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}
.bTop{
  border-top: 1px solid #f5f5f5;
}
.bBottom{
  border-bottom: 1px solid #f5f5f5;
}

.mian_row {
  // line-height: 35px;

  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  background: #fff;
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}

.ding {
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 45px;
  border-top: 1px solid #dcdcdc;
}

.flex-center {
  padding-right: 10px;
}
</style>